import { getCsfr } from './get-csfr.function';
import { login } from './login/login.function';
import { logout } from './logout.function';
import { getMe } from './user/get-user.function';

export default {
  login,
  logout,
  getMe,
  getCsfr,
};
