import auth from '@api/auth';
import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { tokenAtom } from '../atoms/token';
import { getUserAtom, setUserAtom } from '../atoms/user';

export function useLogoutAtomCallback() {
  return useAtomCallback<void, void>(
    useCallback((get, set) => {
      if (!get(getUserAtom)) {
        return;
      }

      auth.logout();
      set(tokenAtom, undefined);
      set(setUserAtom, null);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );
}
