import { Variants } from 'framer-motion';

export const navigationVariants: Variants = {
  initial: {
    clipPath: 'circle(0% at 100% 4rem)',
  },
  enter: {
    clipPath: 'circle(150% at 100% 4rem)',
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 90,
      restDelta: 2,
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
  exit: {
    clipPath: 'circle(0% at 100% 4rem)',
    opacity: 0,
    pointerEvents: 'none',
    transition: {
      delay: 0.2,
      staggerChildren: 0.1,
      type: 'spring',
      stiffness: 400,
      damping: 90,
    },
  },
};
