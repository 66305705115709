import { atom } from 'jotai';
import { User } from '../models/user.interface';

export const userAtom = atom<User | null>(null);

export const setUserAtom = atom<null, User | null>(null, (_get, set, user) => {
  set(userAtom, user);
});

export const getUserAtom = atom((get) => get(userAtom));
