import { useAtomValue } from 'jotai';
import { getAuthStateAtom } from './atoms/authState';
import { useLoginAtomCallback } from './hooks/useLoginAtomCallback';
import { useLogoutAtomCallback } from './hooks/useLogoutAtomCallback';
import { useValidateTokenCallback } from './hooks/useValidateTokenCallback';

export function useAuth() {
  const { isTokenExpired, isLoading, isError } = useAtomValue(getAuthStateAtom);
  const login = useLoginAtomCallback();
  const logout = useLogoutAtomCallback();
  const validateToken = useValidateTokenCallback();

  return { login, logout, validateToken, isTokenExpired, isLoading, isError };
}
