import { Box } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, PropsWithChildren } from 'react';

type Props = {
  pathname: string;
};

const variants = {
  intial: {
    opacity: 0,
  },
  enter: {
    opacity: [0, 1],
    transition: {
      duration: 0.5,
      // delay: 0.5,
      // when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const Main: FC<PropsWithChildren<Props>> = ({ children, pathname }) => {
  return (
    <AnimatePresence>
      <motion.main
        id='content'
        key={pathname}
        variants={variants}
        animate='enter'
        exit='exit'
      >
        <Box width='100%'>{children}</Box>
      </motion.main>
    </AnimatePresence>
  );
};

export default Main;
