import { Box } from '@chakra-ui/react';
import clsx from 'clsx';
import { memo } from 'react';
import * as styles from '../header.module.scss';

type HamburgerMenuProps = {
  onClick: () => void;
  navigationState: boolean;
};

const HamburgerMenu = ({ onClick, navigationState }: HamburgerMenuProps) => (
  <Box
    as='button'
    key='hamburger'
    className={styles.hamburgerMenu}
    onClick={onClick}
    type='button'
    zIndex={navigationState ? 99 : 'initial'}
  >
    <div
      className={clsx([styles.navIcon], { [styles.close]: navigationState })}
    >
      {/* Neccessary div! */}
      <div />
    </div>
  </Box>
);

export default memo(HamburgerMenu);
