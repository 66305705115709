import axios from 'axios';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const baseAtom = atomWithStorage<string | undefined>('token', undefined);

export const tokenAtom = atom(
  (get) => get(baseAtom),
  (_, set, token: string | undefined) => {
    setAxiosAuthHeader(token);
    set(baseAtom, token);
  }
);

export const setTokenAtom = atom(null, (_get, set, token?: string) => {
  set(tokenAtom, token);
});

export const getTokenAtom = atom((get) => get(tokenAtom));

export const removeTokenAtom = atom(null, (_get, set) => {
  set(tokenAtom, undefined);
});

function setAxiosAuthHeader(token: string | undefined) {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
}
