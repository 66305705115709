import auth from '@api/auth';
import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { setAuthStateAtom } from '../atoms/authState';
import { tokenAtom } from '../atoms/token';
import { userAtom } from '../atoms/user';

export function useValidateTokenCallback() {
  return useAtomCallback(
    useCallback(async (get, set): Promise<void> => {
      set(setAuthStateAtom, { isLoading: true });

      try {
        const me = await auth.getMe();
        set(userAtom, me);
        set(setAuthStateAtom, { tokenExpired: false });
      } catch {
        if (get(tokenAtom)) {
          set(setAuthStateAtom, { tokenExpired: true });
        }
        set(userAtom, null);
        set(tokenAtom, undefined);
      } finally {
        set(setAuthStateAtom, { isLoading: false });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );
}
