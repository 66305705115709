import { Box, Center } from '@chakra-ui/react';
import { MotionBox } from '@components/shared/MotionChakra';
import { getUserAtom, useAuth } from '@feature/auth';
import { StaticImage } from 'gatsby-plugin-image';
import { useAtomValue } from 'jotai';
import { FC, useEffect, useRef } from 'react';
import { isBrowser } from 'utils';

const variants = {
  start: {
    skewX: ['90deg', '0deg', '0deg', '0deg'],
    filter: [
      'hue-rotate(90deg)',
      'hue-rotate(0deg)',
      'hue-rotate(0deg)',
      'hue-rotate(0deg)',
    ],
    opacity: [1, 1, 1, 1],
    transition: {
      duration: 0.7,
      delay: 0.2,
    },
  },
  pulsing: {
    scale: [1, 1.8],
    opacity: [1, 0],
    transition: {
      duration: 1.5,
      repeat: Infinity,
      delay: 1,
      ease: 'circOut',
    },
  },
};

export const NotLoggedIn: FC = () => {
  const { isLoading } = useAuth();
  const user = useAtomValue(getUserAtom);
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    const shouldRedirect = user !== null && !isLoading && isBrowser();

    if (shouldRedirect) {
      window.location.replace('https://streetfocus.ch/stay-tuned');
    }
  }, [user, isLoading]);

  return (
    <Box as='section' height='100vh'>
      <Center h='100%' flexDirection='column' gridGap='2rem'>
        <MotionBox
          position='relative'
          animate='start'
          variants={variants}
          opacity='0'
        >
          <StaticImage
            src='../../../../images/logo/logo_80.webp'
            alt='Streetfocus Logo'
            placeholder='blurred'
            height={80}
          />
          <MotionBox
            animate='pulsing'
            variants={variants}
            position='absolute'
            top='0'
          >
            <StaticImage
              src='../../../../images/logo/logo_80.webp'
              alt='Pulsing Streetfocus Logo'
              placeholder='none'
              height={80}
            />
          </MotionBox>
        </MotionBox>
      </Center>
    </Box>
  );
};
