/// <reference types="@welldone-software/why-did-you-render" />
import { GatsbyBrowser } from 'gatsby';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
// styles
import './src/styles/global.scss';
import WrapPageElement from './wrapPageElement';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] =
  WrapPageElement;

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    });
  }
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => <HelmetProvider>{element}</HelmetProvider>;
