import { AnimatePresence, useScroll } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { FC, useEffect, useState } from 'react';
import { isScrollIndicatorVisibleAtom } from './atoms/scroll-indicator-visibility';
import { ScrollProgress } from './ScrollProgress';

export const ScrollIndicator: FC = () => {
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const isScrollIndicatorVisible = useAtomValue(isScrollIndicatorVisibleAtom);
  const { scrollYProgress } = useScroll();

  useEffect(() => {
    scrollYProgress.onChange((v) => setHasScrollbar(v > 0));

    return function cleanup() {
      scrollYProgress.destroy();
    };
  }, [scrollYProgress]);

  return (
    <AnimatePresence>
      {hasScrollbar && isScrollIndicatorVisible ? <ScrollProgress /> : null}
    </AnimatePresence>
  );
};
