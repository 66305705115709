import { Variants } from 'framer-motion';

export const bounceVariants: Variants = {
  bounce: {
    scaleX: [1, 1.1, 0.9, 1, 1],
    scaleY: [1, 0.9, 1.1, 1, 1],
    translateY: [0, 0, -5, 0, 0],
    transition: {
      duration: 0.5,
      ease: 'easeIn',
    },
  },
};
