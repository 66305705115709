import clsx from 'clsx';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { forwardRef } from 'react';
import * as styles from '../header.module.scss';
import { navigationItemVariants } from './variants/NavigationItemVariants';

type NavigationItemProps = {
  linkPath: string;
  linkName: string;
};

const NavigationItem = forwardRef<HTMLLIElement, NavigationItemProps>(
  ({ linkPath, linkName }, ref) => (
    <motion.li
      variants={navigationItemVariants}
      className={clsx(styles.linkItem, 'noSelect')}
      ref={ref}
    >
      <Link to={linkPath}>{linkName}</Link>
    </motion.li>
  )
);

export default NavigationItem;
