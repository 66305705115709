import Header from '@components/core/Layout/components/Header';
import { ScrollIndicator } from '@components/shared/ScrollIndicator';
import { getUserAtom } from '@feature/auth';
import { PageProps } from 'gatsby';
import { useAtom, useAtomValue } from 'jotai';
import { FC, useEffect } from 'react';
import { isBrowser, isDevelopment } from 'utils';
import { pathNameAtom } from './atoms/path-name';
import Footer from './components/Footer';
import Main from './components/Main/Main';
import { NotLoggedIn } from './components/NotLoggedIn';

interface Props extends PageProps {
  // We pass children from the wrapPageElement component
  children: any;
}

const Layout: FC<Props> = ({ children, location: { pathname } }) => {
  const [currentPathName, setCurrentPatName] = useAtom(pathNameAtom);
  const user = useAtomValue(getUserAtom);

  useEffect(() => {
    if (currentPathName !== pathname && isBrowser()) {
      window.scrollTo(0, 0);
    }

    setCurrentPatName(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (!isDevelopment && !user && pathname !== '/login/') {
    return <NotLoggedIn />;
  }

  return (
    <>
      <Header />
      <Main pathname={pathname}>{children}</Main>
      <Footer />
      <ScrollIndicator />
    </>
  );
};
export default Layout;
