import { atom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';

const isHeaderVisibleAtomAtom = atom(true);

export const isHeaderVisibleAtom = atom((get) => get(isHeaderVisibleAtomAtom));

export const hideHeaderAtom = atom(null, (_get, set) => {
  set(isHeaderVisibleAtomAtom, false);
});

export const showHeaderAtom = atom(null, (_get, set) => {
  set(isHeaderVisibleAtomAtom, true);
});

export function useHeaderAtom() {
  return {
    show: useUpdateAtom(showHeaderAtom),
    hide: useUpdateAtom(hideHeaderAtom),
  };
}
