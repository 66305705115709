import { atom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import {
  hideFooterAtom,
  showFooterAtom,
  useFooterAtom,
} from '../components/Footer';
import {
  hideHeaderAtom,
  showHeaderAtom,
  useHeaderAtom,
} from '../components/Header';

const showAtom = atom(null, (_get, set) => {
  set(showHeaderAtom);
  set(showFooterAtom);
});

const hideAtom = atom(null, (_get, set) => {
  set(hideHeaderAtom);
  set(hideFooterAtom);
});

export function useLayoutAtom() {
  return {
    header: useHeaderAtom(),
    footer: useFooterAtom(),
    hide: useUpdateAtom(hideAtom),
    show: useUpdateAtom(showAtom),
  };
}
