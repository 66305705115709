import auth from '@api/auth';
import axios from 'axios';
import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { getAuthStateAtom, setAuthStateAtom } from '../atoms/authState';
import { tokenAtom } from '../atoms/token';
import { userAtom } from '../atoms/user';

export interface LoginArgs {
  email: string;
  password: string;
}

export function useLoginAtomCallback() {
  return useAtomCallback<void, LoginArgs>(
    useCallback(
      async (get, set, { email, password }) => {
        const { isLoading } = get(getAuthStateAtom);

        if (isLoading || get(userAtom)) {
          return;
        }

        set(setAuthStateAtom, { isError: false, isLoading: true });

        try {
          await auth.getCsfr();

          const response = await auth.login({
            email,
            password,
          });

          if (!response) {
            throw new Error('Login failed');
          }

          set(tokenAtom, response.access_token);
          axios.defaults.headers.common.Authorization = `Bearer ${response.access_token}`;

          const me = await auth.getMe();

          set(userAtom, me);
          set(setAuthStateAtom, { tokenExpired: false });
        } catch {
          set(tokenAtom, undefined);
          set(setAuthStateAtom, { isError: true });
          set(userAtom, null);
        }
        set(setAuthStateAtom, { isLoading: false });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )
  );
}
