export const navigationItemVariants = {
  initial: {
    opacity: 0,
    x: -100,
  },
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.1,
      ease: [0.35, -1.02, 0.59, 1.83],
    },
  },
  exit: {
    opacity: 0,
    x: -100,
    transition: {
      duration: 0.1,
      ease: [0.35, -1.02, 0.59, 1.83],
    },
  },
};
