import { Box, Center } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { FC } from 'react';
import { isFooterVisibleAtom } from './atoms/visibility';

const footerHeight = '10rem';

const Footer: FC = () => {
  const isFooterVisible = useAtomValue(isFooterVisibleAtom);

  if (!isFooterVisible) {
    return null;
  }

  return (
    <Box h={footerHeight} as='footer'>
      <Center h='100%'>
        © {new Date().getFullYear()} STREETFOCUS All Rights Reserved
      </Center>
    </Box>
  );
};

export default Footer;
