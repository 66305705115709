/* eslint-disable jsx-a11y/html-has-lang */
import { bounceVariants } from '@animations/variants';
import {
  Box,
  Flex,
  HStack,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useAtomValue } from 'jotai';
import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { isHeaderVisibleAtom } from './atoms/visibility';
import HamburgerMenu from './components/HamburgerMenu';
import Navigation from './components/Navigation';
import navigationItemsData from './data/navigation-items.json';

const Header: FC = () => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const [isLargerThan1440] = useMediaQuery('(min-width: 1440px)');
  const isHeaderVisible = useAtomValue(isHeaderVisibleAtom);

  if (!isHeaderVisible) return null;

  return (
    <>
      <Helmet>
        <html style={{ overflow: isOpen ? 'overflow-hidden' : 'unset' }} />
      </Helmet>

      <Flex
        as='header'
        position='absolute'
        justify='center'
        align='center'
        h='var(--header-height)'
        w='full'
      >
        <Box maxW='8xl' w='full' h='full' paddingInline='6' position='relative'>
          <Flex
            paddingLeft='2rem'
            paddingRight='2rem'
            h='full'
            align='center'
            justify='space-between'
          >
            <motion.div whileHover='bounce'>
              <Link to='/' onClick={onClose}>
                <HStack>
                  <motion.div variants={bounceVariants}>
                    <StaticImage
                      src='../../../../../images/logo/logo_80.webp'
                      alt='Streetfocus Logo'
                      placeholder='blurred'
                      height={25}
                    />
                  </motion.div>
                  <Text
                    fontSize='1.2rem'
                    stroke='1px red'
                    letterSpacing='0.2rem'
                  >
                    STREETFOCUS
                  </Text>
                </HStack>
              </Link>
            </motion.div>

            <Navigation
              open={isOpen}
              hamburger={!isLargerThan1440}
              items={navigationItemsData}
              onClick={onClose}
            />

            {!isLargerThan1440 ? (
              <HamburgerMenu onClick={onToggle} navigationState={isOpen} />
            ) : null}
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Header;
