import type { GatsbyBrowser } from 'gatsby';
import React from 'react';
import Layout from './src/components/core/Layout';
import { AuthPreloader } from './src/feature/auth';

const WrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => (
  <>
    <AuthPreloader />
    <Layout {...props}>{element}</Layout>
  </>
);

export default WrapPageElement;
