import {
  extendTheme,
  theme as baseTheme,
  ThemeOverride,
} from '@chakra-ui/react';
import { components } from './components';
import styles from './styles';

const overrides: ThemeOverride = {
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  components,
  styles,
};

const theme = extendTheme(overrides, baseTheme);

export default theme;
