import { atom } from 'jotai';

const scrollIndicatorVisibleAtom = atom(true);

export const isScrollIndicatorVisibleAtom = atom((get) =>
  get(scrollIndicatorVisibleAtom)
);

export const showScrollIndicatorAtom = atom(null, (_get, set) => {
  set(scrollIndicatorVisibleAtom, true);
});

export const hideScrollIndicatorAtom = atom(null, (_get, set) => {
  set(scrollIndicatorVisibleAtom, false);
});

export const setScrollIndicatorVisibilityAtom = atom(
  null,
  (_get, set, value: boolean) => {
    set(scrollIndicatorVisibleAtom, value);
  }
);
