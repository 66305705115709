import { atom } from 'jotai';

interface State {
  tokenExpired?: boolean;
  isLoading?: boolean;
  isError?: boolean;
}

const authStateAtom = atom({
  isTokenExpired: false,
  isLoading: false,
  isError: false,
});

export const setAuthStateAtom = atom<null, State>(null, (get, set, value) => {
  const currentState = get(authStateAtom);

  set(authStateAtom, {
    ...currentState,
    ...value,
  });
});

export const getAuthStateAtom = atom((get) => get(authStateAtom));
