import { Box, chakra, Portal } from '@chakra-ui/react';
import { currentPathNameAtom } from '@components/core/Layout';
import { MotionBox } from '@components/shared/MotionChakra';
import { AnimatePresence, motion, Transition } from 'framer-motion';
import { Link } from 'gatsby';
import { useAtomValue } from 'jotai';
import { FC, memo } from 'react';
import * as styles from '../header.module.scss';
import NavigationItem from './NavigationItem';
import { navigationVariants } from './variants/NavigationVariants';

type Props = {
  open: boolean;
  hamburger: boolean;
  items: Array<{ name: string; path: string }>;
  onClick: () => void;
};

const spring: Transition = {
  type: 'spring',
  stiffness: 500,
  damping: 30,
};

const white = 'hsl(0, 0%, 100%)';
const gray = 'hsl(0, 0%, 50.2%)';

const ChakraLink = chakra(Link);

const Navigation: FC<Props> = ({ open, hamburger, items, onClick }) => {
  const currentPathName = useAtomValue(currentPathNameAtom);

  if (!hamburger) {
    return (
      <Box display='flex' as='nav' justifyContent='end' alignItems='center'>
        {items.map(({ name, path }) => (
          <MotionBox
            layoutId={name}
            key={name}
            initial={false}
            animate={{
              color: currentPathName === path ? white : gray,
            }}
            whileHover={{
              color: white,
            }}
          >
            <Box position='relative' display='flex' justifyContent='center'>
              <ChakraLink p='2rem' to={path}>
                {name.toUpperCase()}
              </ChakraLink>
              {currentPathName === path ? (
                <motion.div
                  layoutId='dot'
                  initial={false}
                  transition={spring}
                  style={{
                    position: 'absolute',
                    width: '0.3rem',
                    height: '0.3rem',
                    top: '4rem',
                    background: '#d3bb27',
                    borderRadius: '50%',
                  }}
                />
              ) : null}
            </Box>
          </MotionBox>
        ))}
      </Box>
    );
  }

  return (
    <Portal>
      <AnimatePresence exitBeforeEnter>
        {open && (
          <Box as='nav' position='fixed' top={0} left={0} right={0} bottom={0}>
            <motion.ul
              className={styles.linkList}
              onClick={onClick}
              variants={navigationVariants}
              initial='initial'
              animate='enter'
              exit='exit'
            >
              {items.map(({ name, path }) => (
                <NavigationItem key={name} linkName={name} linkPath={path} />
              ))}
            </motion.ul>
          </Box>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default memo(Navigation);
