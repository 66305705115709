import axios from 'axios';
import { ILoginResponseDto } from './login-response.dto';
import { ILoginDto } from './login.dto';

const url = `${process.env.GATSBY_TWILL_URL}/api/login`;

export async function login(loginInput: ILoginDto) {
  return axios
    .post<ILoginResponseDto>(url, loginInput)
    .then((response) => response.data);
}
