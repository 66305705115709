import { atom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';

const isFooterVisibleAtomAtom = atom(true);

export const isFooterVisibleAtom = atom((get) => get(isFooterVisibleAtomAtom));

export const hideFooterAtom = atom(null, (_get, set) => {
  set(isFooterVisibleAtomAtom, false);
});

export const showFooterAtom = atom(null, (_get, set) => {
  set(isFooterVisibleAtomAtom, true);
});

export function useFooterAtom() {
  return {
    show: useUpdateAtom(showFooterAtom),
    hide: useUpdateAtom(hideFooterAtom),
  };
}
