import { Portal } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { scrollToTop } from '@utils';
import { motion, useScroll, useSpring, useTransform } from 'framer-motion';
import { FC, useCallback, useEffect } from 'react';

const SvgContainer = styled(motion.svg)`
  position: fixed;
  width: 80px;
  height: 80px;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ProgressCircleIndicator = styled(motion.path)`
  fill: none;
  stroke-width: 2px;
  stroke: #ffc800;
  transform: rotate(-90deg) scaleX(-1) translateX(5px) translateY(5px)
    translateZ(0px);
  transform-origin: 20px 20px;
`;

const Background = styled(motion.path)`
  fill: none;
  stroke-width: 2px;
  stroke: #ffc80021;
  transform-origin: 20px 20px;
  transform: rotate(-90deg) scaleX(-1) translateX(5px) translateY(5px)
    translateZ(0px);
`;

const ArrowUp = styled(motion.path)`
  fill: none;
  stroke-width: 1.5px;
  stroke: white;
`;

export const ScrollProgress: FC = () => {
  const { scrollYProgress } = useScroll();
  const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1]);
  const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });
  const scrollToTopCallback = useCallback(() => scrollToTop(), []);

  useEffect(() => {
    return function cleanup() {
      yRange.destroy();
      scrollYProgress.destroy();
    };
  }, [yRange, scrollYProgress]);

  return (
    <Portal>
      <SvgContainer
        viewBox='0 0 60 60'
        className='noSelect'
        initial={false}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={scrollToTopCallback}
      >
        <Background d='M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0' />
        <ProgressCircleIndicator
          d='M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0'
          style={{ pathLength }}
        />

        <ArrowUp d='M15,30L25,20L35,30' />
      </SvgContainer>
    </Portal>
  );
};
