/**
 * Checks whether the execution context is type of window
 * Compatibility issues gatsby
 */
export function isBrowser(): boolean {
  return typeof window !== 'undefined';
}

/**
 * Scrolls the window back to top
 * @param smooth Default = true; Whether the scroll should be smooth
 */
export function scrollToTop(smooth = true): void {
  if (isBrowser()) {
    window.scrollTo({ top: 0, behavior: smooth ? 'smooth' : 'auto' });
  }
}

export const isDevelopment = process.env.NODE_ENV === 'development';

export function noop(): void {}

export enum Locales {
  en = 'en',
  de = 'de',
}
