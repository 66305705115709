import { atom } from 'jotai';

const pathNameAtomAtom = atom('');

export const pathNameAtom = atom(
  (get) => get(pathNameAtomAtom),
  (_, set, value: string) => {
    set(pathNameAtomAtom, removeTrailingSlash(value));
  }
);

export const currentPathNameAtom = atom((get) => get(pathNameAtomAtom));

function removeTrailingSlash(value: string): string {
  const endsWithSlash = value.length > 1 && value.endsWith('/');
  return endsWithSlash ? value.slice(0, -1) : value;
}
