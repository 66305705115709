import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { tokenAtom } from './atoms/token';
import { useAuth } from './useAuth';

function setAxiosAuthHeader(token: string | undefined) {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
}

export const AuthPreloader = () => {
  const { validateToken } = useAuth();
  const token = useAtomValue(tokenAtom);

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common = {
      ...axios.defaults.headers.common,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    setAxiosAuthHeader(token);

    if (token) {
      validateToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
